import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import servicesStyles from "../styles/pages/services.module.css"

const ServicesPage = ({ data }) => (
  <Layout location="services">
    <SEO title="Services" />
    <div className={ servicesStyles.header }>
      <h1>What We Offer</h1>
      <hr/>
      <p>You name it, and we will provide solutions for your event management needs.</p>
    </div>
    <div className={ servicesStyles.servicesGroup1 }>
      <div className={ servicesStyles.servicesGroup1ContentWrapper }>
        <div className={ servicesStyles.servicesGroup1Content }></div>
      </div>
      <div className={ servicesStyles.services }>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image1.childImageSharp.fluid } />
          </div>
          <p>Conferences & Seminars</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image2.childImageSharp.fluid } />
          </div>
          <p>Product Launches</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image3.childImageSharp.fluid } />
          </div>
          <p>Team Building & Workshops</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image4.childImageSharp.fluid } />
          </div>
          <p>Trade Exhibits</p>
        </div>
      </div>
    </div>
    <div className={ servicesStyles.servicesGroup2 }>
      <div className={ servicesStyles.servicesGroup2ContentWrapper }>
        <div className={ servicesStyles.servicesGroup2Content }></div>
      </div>
      <div className={ servicesStyles.services }>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image5.childImageSharp.fluid } />
          </div>
          <p>Anniversaries</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image6.childImageSharp.fluid } />
          </div>
          <p>Celebrations</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image7.childImageSharp.fluid } />
          </div>
          <p>Awards Gala Nights</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image8.childImageSharp.fluid } />
          </div>
          <p>Entertainment Performances</p>
        </div>
      </div>
    </div>
    <div className={ servicesStyles.servicesGroup3 }>
      <div className={ servicesStyles.servicesGroup3ContentWrapper }>
        <div className={ servicesStyles.servicesGroup3Content }></div>
      </div>
      <div className={ servicesStyles.services }>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image9.childImageSharp.fluid } />
          </div>
          <p>Destination Events</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image10.childImageSharp.fluid } />
          </div>
          <p>Video-based Activities</p>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imgContainer }>
            <Img fluid={ data.image11.childImageSharp.fluid } />
          </div>
          <p>Customized Events</p>
        </div>
      </div>
    </div>
  </Layout>
)

export const squareImg = graphql`
  fragment squareImg on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "CONFERENCES & SEMINARS.png" }) {
      ...squareImg
    }
    image2: file(relativePath: { eq: "PRODUCT-LAUNCHES.png" }) {
      ...squareImg
    }
    image3: file(relativePath: { eq: "TEAM BUILDING & WORKSHOPS.png" }) {
      ...squareImg
    }
    image4: file(relativePath: { eq: "TRADE EXHIBITS.png" }) {
      ...squareImg
    }
    image5: file(relativePath: { eq: "ANNIVERSARIES.png" }) {
      ...squareImg
    }
    image6: file(relativePath: { eq: "CELEBRATIONS.png" }) {
      ...squareImg
    }
    image7: file(relativePath: { eq: "AWARDS GALA NIGHTS.png" }) {
      ...squareImg
    }
    image8: file(relativePath: { eq: "ENTERTAINMENT PERFORMANCES & SHOWS.png" }) {
      ...squareImg
    }
    image9: file(relativePath: { eq: "DESTINATION EVENTS.png" }) {
      ...squareImg
    }
    image10: file(relativePath: { eq: "VIDEO-BASED ACTIVITIES.png" }) {
      ...squareImg
    }
    image11: file(relativePath: { eq: "CUSTOMIZED EVENTS.png" }) {
      ...squareImg
    }
  }
`

export default ServicesPage